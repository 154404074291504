import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import GridRow from './_grid-row';
import DeleteIcon from '@material-ui/icons/Delete';
import profileStyle from "../assets/scss/profile.module.scss";
import Button from '@material-ui/core/Button';






export default function ProfileOptions({ title, subTitle, icon }) {

  return (
    

              <Button
                variant="contained"
                className={[ profileStyle.memberBtn].join(" ")}
                
              >
                <div className={profileStyle.memberBox}>
                  <div className={profileStyle.memberSubIcone}>
                    {icon}
                  </div>

                  <div  className={profileStyle.memberBoxCont} >
                    <div>
                    <p className={["subtitle", profileStyle.memberTitle].join(" ")} >{title}</p>
                    <p className="body-03">{subTitle}</p>
                    </div>
                    

                  </div>
                  

                </div>


              </Button>
           
  );
}

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import profileStyle from "../assets/scss/profile.module.scss"
import profileImage from "../images/Ellipse1@2x.png";
import { Button } from "@material-ui/core";
import { Container, Grid } from '@material-ui/core';
import GridRow from './_grid-row';
import Box from './boxes';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PersonIcon from '@material-ui/icons/Person';
import TimerIcon from '@material-ui/icons/Timer';
import ProfileOptions from './profile-options-component';
import TextField from '@material-ui/core/TextField';
import UserContext from '../providers/user-provider'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
const spinner = css`
  display: block;
  margin: 0 auto;
`;


export default function ProfileComponent() {
    const { user, isLoading, registeredClasses } = useContext(UserContext);
    const [upcomingInfo, setUpcomingInfo] = useState([]);
    let todaysDate = new Date();
    function checkDate(date) {
        if (date && date !== null) {
            let pickedDate = new Date(date);
            return pickedDate > todaysDate
        } else {
            return false
        }
    }

    useEffect(async () => {
        const upcomingFiltered = registeredClasses.filter(classItem => {
            if (checkDate(classItem.Class.start_time)) {
                return true;
            } else {
                return false
            }
        });

        setUpcomingInfo(upcomingFiltered);
    }, [registeredClasses])

    console.log('ashh profile', user)
    return (
        isLoading ?

            <div className="loaderWrapper">
                <ClipLoader
                    css={spinner}
                    size={150}
                    color={"#fada4d"}
                />
            </div>
            :

            <>

                <div className={`${profileStyle.wrapper} profile-container`} >
                    <Container maxWidth="xl">
                        <GridRow className={profileStyle.justifyCenter}>
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12} lg={8} >
                                    <div className={profileStyle.flex}>
                                        <div className={profileStyle.imageContent}>
                                            <img src={profileImage} className={profileStyle.profileImage} />
                                            <div className={profileStyle.inf}>
                                                <h3 className={profileStyle.Name}>{user.id ? user.personal.first_name + ' ' + user.personal.last_name : ""}</h3>
                                                <p className={profileStyle.position}>{user.more && user.more.describe ? user.more.describe : ""}</p>
                                            </div>
                                        </div>
                                        <Link to="/edit-profile" className={profileStyle.editButton}>Edit information</Link>

                                    </div>
                                    <div className={profileStyle.boxesWrapper}>
                                        {/* <Box icon={<WhatshotIcon />} number="90k" text="Calories Burned" /> */}
                                        <Box icon={<PersonIcon />} number={upcomingInfo.length} text="Classes Left to Attend" />
                                        <Box icon={<TimerIcon />} number="600" text="Minutes of Playtime" />
                                    </div>

                                    <div className={profileStyle.btnsWrapper}>
                                        {/* <ProfileOptions icon={<i class="fas fa-dumbbell"></i>} title="MemberShip" subTitle="6 Months" /> */}
                                        {user.more.goals.length > 0? <ProfileOptions icon={<i class="fas fa-bullseye"></i>} title={user.more.goals.length > 1? "Goals" : "Goal"}
                                            subTitle={user.more.goals.map((goal, index, arr) => {
                                                return (
                                                    (arr.length > 1 && index == (arr.length - 1)) || arr.length == 1 ? goal
                                                        : `${goal}, `
                                                )
                                            })} /> : ""}

                                    </div>
                                    <form noValidate autoComplete="off" className={["profileForm", profileStyle.profileForm].join(" ")} fullWidth="true" readOnly="true">
                                        <div className={`${profileStyle.inputComp} inputComp`}>
                                            <TextField fullWidth InputProps={{
                                                readOnly: true,
                                            }}
                                                id="standard-required"
                                                label="E-mail"
                                                defaultValue={user.id ? user.personal.email : "email@email.com"} />
                                        </div>
                                        <div className={`${profileStyle.inputComp} inputComp`} >
                                            <TextField
                                                id="standard-password-input"
                                                label="Password"
                                                type="password"
                                                autoComplete="current-password"
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                defaultValue="******"

                                            />
                                        </div>
                                        <div className={`${profileStyle.inputComp} inputComp`}>
                                            <TextField fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                id="standard-required" label="Age" defaultValue={user.id ? user.health.age : "Age"} />

                                        </div>
                                    </form>

                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>


            </>
    )

}

import React from 'react';
import classStyles from '../assets/scss/class-internal.module.scss'





export default function Box({ icon, number, text }) {

    return (

        <div className={classStyles.box}>
            <div className={classStyles.info}>
                {icon}
                <h2 className="h2">{number}</h2>
            </div>

            <div className={["text-02", classStyles.infoText].join(" ")}>{text}</div>
        </div>
        
    );
}

import * as React from "react"
import { Router } from "@reach/router";
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import ProfileComponent from "../components/Profile"

import Seo from "../components/seo"


const ProfilePage = () => (
  <Layout pageName="Profile">
    <Seo title="Profile" />
    <Router>
      <PrivateRoute path="/profile" component={ProfileComponent}/>
    </Router>
  </Layout>
)

export default ProfilePage
